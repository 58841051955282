import React, { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import styled from "styled-components";
import {
  Form,
  Spin,
  Input,
  Button,
  Row,
  Col,
  message,
  Radio,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { getProfileDetails, updateProfileDetails } from "../../api/ProfileApi";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import _ from "lodash";
import { StyledComponentWrapper, Title } from "./Profile.styles";

const ButtonContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
  > .ant-form-item {
    margin-bottom: unset;
  }
`;

const ProfileData = () => {
  const [form] = Form.useForm();
  const [profileValue, setProfileValues] = useState();
  const [profileLoading, setProfileLoading] = useState(true);
  const [otpVia, setOtpVia] = useState();

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const profileData = () => {
    setProfileLoading(true);
    getProfileDetails()
      .then((res) => {
        if (res.data.payload) {
          const { payload } = res.data;
          const data = {
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            phone: payload.phone,
            enableEmailAuthentication: payload.enableEmailAuthentication,
            enablePhoneAuthentication: payload.enablePhoneAuthentication,
          };
          form.setFieldsValue(data);
          setProfileValues(data);
          setProfileLoading(false);
        }
      })
      .catch(() => {
        setProfileLoading(false);
      });
  };

  useEffect(() => {
    profileData(); // eslint-disable-next-line
  }, []);

  const updateProfile = (values) => {
    if (_.isEqual(values, profileValue)) {
      message.info("Please provide values to update the profile !");
    } else {
      setProfileLoading(true);

      const updateValue = {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone.toString(),
      };
      updateProfileData(updateValue);
    }
  };

  const updateProfileData = (updateValue) => {
    updateProfileDetails(updateValue)
      .then((res) => {
        if (res.data.success) {
          setProfileLoading(false);
          profileData();
          message.success("Profile Updated Successfully !");
        }
      })
      .catch((error) => {
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
            setProfileLoading(false);
          } else {
            message.error("Unable to Edit  Profile. Please try again!");
          }
        } else {
          message.error("Unable to Edit Profile. Please try again!");
        }

        setProfileLoading(false);
      });
  };

  const onChangeAuthentication = (e) => {
    const { value } = e.target;
    const data = {};
    if (value === "email") {
      data["enableEmailAuthentication"] = true;
      data["enablePhoneAuthentication"] = false;
    } else if (value === "phone") {
      data["enablePhoneAuthentication"] = true;
      data["enableEmailAuthentication"] = false;
    } else if (value === "remove") {
      data["enableEmailAuthentication"] = false;
      data["enablePhoneAuthentication"] = false;
    }

    setProfileLoading(true);
    updateProfileData(data);
  };

  useEffect(() => {
    if (profileValue) {
      if (profileValue.enableEmailAuthentication) {
        setOtpVia("email");
      } else if (profileValue.enablePhoneAuthentication) {
        setOtpVia("phone");
      } else {
        setOtpVia("remove");
      }
    }
  }, [profileValue]);

  return (
    <Spin spinning={profileLoading} indicator={LoadingOutlined}>
      <>
        <StyledComponentWrapper>
          <Title>My Profile</Title>
          <Form
            layout="vertical"
            form={form}
            size="large"
            labelAlign="left"
            onFinish={updateProfile}
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Input First Name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Input Last Name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item label="E-mail" name="email">
                  <Input placeholder="Email" disabled={true} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <PhoneInput
                    placeholder="Enter Phone Number"
                    onlyCountries={["us"]}
                    disableDropdown="false"
                    country="us"
                  />
                </Form.Item>
                <ButtonContainer span={24}>
                  <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
                    <Button type="primary" size={"large"} htmlType="submit">
                      Save Changes
                    </Button>
                  </Form.Item>
                </ButtonContainer>
              </Col>
            </Row>
          </Form>
        </StyledComponentWrapper>

        <StyledComponentWrapper>
          <Title>Select Authentication Method</Title>

          <Radio.Group
            onChange={onChangeAuthentication}
            value={otpVia && otpVia}
          >
            <Radio value={"email"}>E-mail</Radio>
            <Radio value={"phone"}>Phone</Radio>
            <Radio value={"remove"}>No-authentication</Radio>
          </Radio.Group>
        </StyledComponentWrapper>
      </>
    </Spin>
  );
};

export default ProfileData;

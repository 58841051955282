//import packages
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Badge,
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Typography,
  Tooltip,
} from "antd";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { CSVLink } from "react-csv";

//import components
import UserRolesAndRightsHOComponent
  from "../../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";

//import other files and API function
import socket from "../../api/socket";
import authorization from "../../auth/authorization";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import CaseTicketsAPI from "../../api/CaseTicketsAPI";
import { getUsersList } from "../../api/UserTypeAPI";
import { usePhysicians } from '../../hooks/usePhysicians';
import { useLookup } from '../../hooks/useLookup';
import { useLocalStorage } from "../../utils/useLocalStorage";
import { PhysicianStatusEnum } from "../../constants/enums";

const { Content } = Layout;
const { TabPane } = Tabs;

const StyledTabWrapper = styled.div`
  width: 100%;
  max-width: 1480px;
  margin: 24px auto;
  @media (max-width: 1800px) {
    max-width: 1300px;
  }
  @media (max-width: 1550px) {
    max-width: 1080px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }
  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 1480px;
  padding: 24px 28px;
  @media (max-width: 1800px) {
    max-width: 1300px;
  }
  @media (max-width: 1550px) {
    max-width: 1150px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }
  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

const StyledTabs = styled(Tabs)`
  > .ant-tabs-bar {
    margin: unset;
  }
  > .ant-tabs-bar
    > .ant-tabs-nav-container
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-scroll
    > .ant-tabs-nav
    > div
    > .ant-tabs-tab-active {
    background: #ffffff;
    border-bottom: unset;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin: 5px 0 0 0;
`;

const StyledButton = styled(Button)`
  width: 80px;
  margin-top: 30px;
`;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  float: right;
  gap: 1em;
  .ant-form-item {
    margin-top: 12px;
  }
`;

const ModalForm = styled(Form)`
  padding-bottom: 20px;
`;

const CaseTicketStatusEnum = {
  OPEN: "Open",
  CLOSED: "Closed",
  SOLVED: "Solved",
}

const { OPEN, CLOSED, SOLVED } = CaseTicketStatusEnum

const statusOptions = [OPEN, CLOSED, SOLVED];

const initialParams = {
  page: 0,
  limit: 10,
  status: OPEN,
};

const messageMap = {
  error: {
    getTicket: "Unable to load Case List. Please try again!",
    updateTicket: "Error occured while updating case status",
  },
  success: {
    updateTicket: "Successfully updated ticket",
  },
  warning: {
    updateTicket: "Choose a different Case Ticket Status for updating",
  },
}

const getFormSelectPhysicianData = (physicians) => {
  const formSelectPhysicians = {
    active: [],
    inactive: [],
  }
  physicians.forEach(p => {
    const physicianStatus = p.status === PhysicianStatusEnum.ACTIVE ? "active" : "inactive";
    const physicianSelectData = {
      _id: p._id,
      name: `${p.firstName || ''} ${p.lastName || ''}${p.credential ? `, ${p.credential}` : ''}`.trim(),
    }
    formSelectPhysicians[physicianStatus].push(physicianSelectData);
  })
  return formSelectPhysicians;
}

const getRenderTooltipTitle = (_case) => () => (
  <div
    style={{ cursor: 'copy' }}
    onClick={(e) => {
      e.stopPropagation();
      navigator.clipboard.writeText(_case._id)
        .then(() => message.success("Copied to clipboard!"))
        .catch((error) => console.error('Error copying text: ', error));
    }}
  >
    {_case._id}
  </div>
)

const CaseTicketComponent = ({ userType }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [formFilters, saveFormFilters, removeSavedFilters] = useLocalStorage('case-tickets-filters', initialParams, JSON.parse);
  const [form] = Form.useForm();
  const { physicians } = usePhysicians();
  const caseStatuses = useLookup('case-status');
  const [ticketList, setTicketList] = useState([]);
  const [rep, setRep] = useState([]);
  const [status, setStatus] = useState(OPEN);
  const [filterParams, setFilterParams] = useState();
  const [modalLoad, setModalLoad] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [currentStatus, setCurrentStatus] = useState();
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(null);
  const [tablePageSize, setTablePageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState();

  const formSelectPhysicians = getFormSelectPhysicianData(physicians);

  const columns = [
    {
      title: "Ticket Id",
      width: 250,
      dataIndex: "",
      key: "ticket-id",
      render: (_case) => (
        <Tooltip placement="topLeft" title={getRenderTooltipTitle(_case)} color="#fff">
          <Badge count={_case.unreadCount} offset={[8, -6]}>
            <div style={{ minWidth: "60px"}}>
              ...{_case._id.slice(-5)}
            </div>
          </Badge>
        </Tooltip>
      )
    },
    {
      title: "Rep",
      width: 250,
      dataIndex: "repName",
      key: "rep",
    },
    {
      title: "Physician",
      width: 250,
      dataIndex: "physicianName",
      key: "physician",
    },
    {
      title: "Case Id",
      width: 250,
      dataIndex: "caseId",
      key: "case-id",
    },
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patient-name",
      width: 250,
    },
    {
      title: "Last Response Time",
      width: 220,
      dataIndex: "",
      key: "last-response-time",
      render: (eachCase) => (
        <>
          {eachCase.lastResponseTime
            ? dateTimeFormatter(eachCase.lastResponseTime)
            : "NA"}
        </>
      ),
    },
    {
      title: "Category",
      width: 200,
      dataIndex: "categoryName",
      key: "category",
    },
    {
      title: "Status",
      width: 40,
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Update Status",
      width: 260,
      dataIndex: "",
      key: "update-status",
      align: "center",
      render: (eachCase) => (
        <>
          <Button
            shape="circle"
            icon={<EditOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              setModalVisibility(true);
              setCurrentStatus(eachCase.status);
              setSelectedTicketId(eachCase.ticketId);
            }}
          />
        </>
      ),
    },
  ];

  const getUnreadMessagesCountByTicketIds = async (ticketIds) => {
    try {
      const res = await CaseTicketsAPI.getUnreadMessagesCountByTicketIds(ticketIds);
      const { success, payload } = res.data;
          if (!success) {
            throw Error();
          }
          setTicketList(list => {
            const ticketIds = Object.keys(payload);
            const ticketMap = new Map(list.map(ticket => [ticket._id, ticket]));
            const newTickets = [];
            ticketIds.forEach(ticketId => {
              if (ticketMap.has(ticketId)) {
                ticketMap.set(ticketId, { ...ticketMap.get(ticketId), ...payload[ticketId] });
              } else {
                newTickets.push(payload[ticketId]);
              }
            });
            return [...newTickets, ...Array.from(ticketMap.values())];
          });
    } catch (error) {
      console.log("Error getting notifications count by ticket ids", error);
    }
  }

  useEffect(() => {
    socket.on("newTicketChatMessageRecieved", (data) => {
      const { users, ticketId } = data;
      if (users?.includes(authorization.getUserId())) {
        getUnreadMessagesCountByTicketIds([ticketId]);
      }
    });
  }, [])

  const fetchRep = () => {
    getUsersList().then((res) => {
      if (res.data?.payload) {
        const repData = res.data.payload.map((eachRep) => ({
          key: eachRep._id,
          value: eachRep.firstName + " " + eachRep.lastName,
        }));
        setRep(repData);
      }
    });
  };

  useEffect(() => {
    fetchRep();
    caseTickets(formFilters);
    form.setFieldsValue(formFilters);
  }, [form, formFilters]);

  const caseTickets = (filterValue) => {
    setLoading(true);
    CaseTicketsAPI.getTicket(filterValue)
      .then((res) => {
        if (!res.data.payload) {
          message.error(messageMap.error.getTicket);
          return;
        }
        setTotalCount(res.data.totalCount);
        setTicketList(res.data.payload);
      })
      .catch((error) => {
        message.error(error.response?.data.message || messageMap.error.getTicket);
      })
      .finally(() => setLoading(false))
  };

  const filterValue = () => {
    const data = form.getFieldsValue();

    const filter = {
      page: 0,
      limit: 10,
      rep: data.rep,
      caseId: data.caseId,
      caseStatus: data.caseStatus,
      patientEmail: data.patientEmail,
      physicianId: data.activePhysicianId || data.inactivePhysicianId,
      status: data.status || OPEN,
    };

    setStatus(data.status || OPEN);
    setCurrentPage(1);
    setTablePageSize(10);
    setFilterParams(filter);
    saveLocallyFormFilters();
    caseTickets(filter);
  };

  const saveLocallyFormFilters = () => {
    saveFormFilters(form.getFieldsValue());
  }

  const clearFilterChange = () => {
    form.resetFields();
    removeSavedFilters();
    setCurrentPage(1);
    setTablePageSize(10);
    setFilterParams();
    setStatus(OPEN);
    caseTickets(initialParams);
  };

  const handleStatusUpdateChange = (event) => {
    setUpdateStatus(event);
  };

  const handleStatusUpdate = () => {
    form.resetFields();
    setModalLoad(true);
    const params = {
      ticketId: selectedTicketId,
      status: updateStatus,
    };
    if (updateStatus !== currentStatus) {
      CaseTicketsAPI.updateTicket(params)
        .then((res) => {
          setModalLoad(false);
          setLoading(false);
          if (res.data?.success) {
            message.success(messageMap.success.updateTicket);
            caseTickets(initialParams);
            setModalVisibility(false);
          } else {
            message.error(res.data.payload);
          }
        })
        .catch((err) => {
          setModalLoad(false);
          setLoading(false);
          setModalVisibility(false);
          message.error(messageMap.error.updateTicket);
        });
    } else {
      message.warning(messageMap.warning.updateTicket);
      setModalLoad(false);
      setModalVisibility(false);
    }
  };

  const handleMarkTicketAsRead = async (ticketId) => {
    await CaseTicketsAPI.markTicketAsRead(ticketId);
  };

  const handleCancel = () => {
    setModalVisibility(false);
    setUpdateStatus("");
  };

  const onChangePage = (value, pageSize) => {
    setLoading(true);
    setCurrentPage(value);
    const paramsData = filterParams || {};
    paramsData["page"] = value - 1;
    paramsData["limit"] = pageSize;
    paramsData["status"] = status;
    caseTickets(paramsData);
  };

  const onPageSizeChange = (current, size) => {
    setTablePageSize(size);
    setCurrentPage(1);
    const paramsData = filterParams || {};
    paramsData["page"] = current - 1;
    paramsData["limit"] = size;
    paramsData["status"] = status;
    caseTickets(paramsData);
  };

  const getOnRowConfig = (record) => ({
    style: { cursor: "pointer" },
    onClick: async () => {
      await handleMarkTicketAsRead(record.ticketId);
      window.localStorage.setItem("physicianChatKey", true);
      history.push(`/cases/${record.caseId}`);
    }
  });

  return (
    <>
      <Content>
        <StyledTabWrapper>
          <StyledTabs type="card">
            {authorization.isAllowed(userType, "caseTicketListing") && (
              <TabPane tab="All" key="1">
                <Spin
                  spinning={loading}
                  size="large"
                  indicator={<LoadingOutlined />}
                >
                  <StyledComponentWrapper>
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Title level={2}>All Tickets</Title>
                      </Col>
                    </Row>
                    <Form form={form} name="ticket-details" layout={"vertical"}>
                      <Row gutter={24} style={{ marginBottom: 10 }}>
                        <Col xs={24} sm={24} md={24} lg={5} xl={6}>
                          <StyledFormItem name="rep" label="Rep">
                            <Select
                              placeholder="Filter by Rep"
                              showSearch
                              getPopupContainer={(trigger) => trigger.parentElement}
                              onChange={filterValue}
                            >
                              {rep?.map((r) => (
                                <Select.Option key={r.key} value={r.value} >
                                  {r.value}
                                </Select.Option>
                              ))}
                            </Select>
                          </StyledFormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={6}>
                          <StyledFormItem name="status" label="Status">
                            <Select
                              placeholder="Filter by Status"
                              defaultValue={OPEN}
                              getPopupContainer={(trigger) => trigger.parentElement}
                              onChange={filterValue}
                            >
                              {statusOptions?.map((status) => (
                                <Select.Option key={status} value={status}>
                                  {status}
                                </Select.Option>
                              ))}
                            </Select>
                          </StyledFormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={7} xl={6}>
                          <StyledFormItem name="caseId" label="Case Id">
                            <Input onChange={saveLocallyFormFilters} />
                          </StyledFormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={7} xl={6}>
                          <StyledFormItem
                            name="caseStatus"
                            label="Case Status"
                          >
                            <Select showSearch optionFilterProp="value" allowClear onChange={saveLocallyFormFilters}>
                              {caseStatuses.map(s => (
                                <Select.Option key={s._id} value={s.name}>{s.name}</Select.Option>
                              ))}
                            </Select>
                          </StyledFormItem>
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={7} xl={6}>
                          <StyledFormItem
                            name="activePhysicianId"
                            label="Active Physician"
                          >
                            <Select showSearch optionFilterProp="children" allowClear onChange={() => {
                              form.setFieldsValue({
                                inactivePhysicianId: undefined,
                              });
                              saveLocallyFormFilters()
                            }}>
                              {formSelectPhysicians.active.map(p => (
                                <Select.Option key={p._id} value={p._id}>
                                  {p.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </StyledFormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={7} xl={6}>
                          <StyledFormItem
                            name="inactivePhysicianId"
                            label="Inactive Physician"
                          >
                            <Select showSearch optionFilterProp="children" allowClear onChange={() => {
                              form.setFieldsValue({
                                activePhysicianId: undefined,
                              });
                              saveLocallyFormFilters()
                            }}>
                              {formSelectPhysicians.inactive.map(p => (
                                <Select.Option key={p._id} value={p._id}>
                                  {p.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </StyledFormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={7} xl={6}>
                          <StyledFormItem
                            name="patientEmail"
                            label="Patient Email Address"
                          >
                            <Input onChange={saveLocallyFormFilters}/>
                          </StyledFormItem>
                        </Col>
                      </Row>
                      <Row justify="space-between">
                        <Col>
                          <StyledFormItem>
                            <StyledButton
                              style={{
                                margin: "10px 5px 10px 0",
                                width: "initial",
                              }}
                              type="primary"
                            >
                              <CSVLink
                                data={ticketList}
                                filename={`${Date.now()}_exported-case-tickets.csv`}
                                target="_blank"
                              >
                                Export to CSV
                              </CSVLink>
                            </StyledButton>
                          </StyledFormItem>
                        </Col>
                        <Col>
                          <Row>
                            <StyledFormItem>
                              <StyledButton
                                style={{ margin: "10px 5px 10px 0" }}
                                type="primary"
                                onClick={filterValue}
                              >
                                Search
                              </StyledButton>
                            </StyledFormItem>
                            <StyledFormItem>
                              <StyledButton
                                style={{ margin: "10px 0 10px 5px" }}
                                type="secondary"
                                onClick={clearFilterChange}
                              >
                                Clear
                              </StyledButton>
                            </StyledFormItem>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                    <Modal
                      open={modalVisibility}
                      destroyOnClose={true}
                      footer={[]}
                      onOk={() => setModalVisibility(false)}
                      onCancel={() => handleCancel()}
                    >
                      <Spin
                        spinning={modalLoad}
                        indicator={<LoadingOutlined />}
                      >
                        <ModalForm
                          form={form}
                          name="update status"
                          onFinish={handleStatusUpdate}
                        >
                          <StyledFormItem
                            label="Case Ticket Status"
                            name="case-ticket-status"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            required={true}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              placeholder="Select Case Ticket Status"
                              onChange={handleStatusUpdateChange}
                              value={statusOptions}
                            >
                              {statusOptions?.map((status) => (
                                <Select.Option key={status} value={status}>
                                  {status}
                                </Select.Option>
                              ))}
                            </Select>
                          </StyledFormItem>
                          <StyledCol>
                            <StyledFormItem>
                              <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                              >
                                Update Status
                              </Button>
                            </StyledFormItem>
                            <StyledFormItem>
                              <Button size="large" onClick={handleCancel}>
                                Cancel
                              </Button>
                            </StyledFormItem>
                          </StyledCol>
                        </ModalForm>
                      </Spin>
                    </Modal>
                    <Col span={24}>
                      <Table
                        columns={columns}
                        dataSource={ticketList}
                        onRow={getOnRowConfig}
                        pagination={{
                          total: totalCount,
                          showTotal: (totalCount) =>
                            `Total ${totalCount} Case Tickets`,
                          current: currentPage,
                          pageSize: tablePageSize,
                          pageSizeOptions: ["5", "10", "20", "50", "100"],
                          showSizeChanger: true,
                          onChange: onChangePage,
                          onShowSizeChange: onPageSizeChange,
                        }}
                        scroll={{ x: 660 }}
                      />
                    </Col>
                  </StyledComponentWrapper>
                </Spin>
              </TabPane>
            )}
          </StyledTabs>
        </StyledTabWrapper>
      </Content>
    </>
  );
};

export default UserRolesAndRightsHOComponent(CaseTicketComponent);

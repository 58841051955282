import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config;

export const getUsersList = () => {
  return axiosInstance.get(`${apiAppendPath.userTypeApiAppendPath}/admin-user`);
};

export const getUsersListByStatus = (status) => {
  return axiosInstance.get(`${apiAppendPath.userTypeApiAppendPath}/admin-user?status=${status}`);
};

export const getUserById = (id) => {
  return axiosInstance.get(
    `${apiAppendPath.userTypeApiAppendPath}/admin-user/${id}`
  );
};

export const deleteUsers = (id) => {
  return axiosInstance.delete(
    `${apiAppendPath.userTypeApiAppendPath}/admin-user/${id}`
  );
};

export const addUsers = (data) => {
  return axiosInstance.post(
    `${apiAppendPath.userTypeApiAppendPath}/admin-user`,
    data
  );
};
export const editUsers = (id, data) => {
  return axiosInstance.put(
    `${apiAppendPath.userTypeApiAppendPath}/admin-user/${id}`,
    data
  );
};

export const getUserClients = () => {
  return axiosInstance.get(`${apiAppendPath.userTypeApiAppendPath}/clients`);
};

export const getUserSingleClient = (id) => {
  return axiosInstance.get(
    `${apiAppendPath.userTypeApiAppendPath}/clients/${id}`
  );
};

export const updateUserPassword = (data) => {
  return axiosInstance.post(
    `${apiAppendPath.userTypeApiAppendPath}/reset-password`,
    data
  );
};

export const switchUser = (data) => {
  return axiosInstance.post(
    `${apiAppendPath.userTypeApiAppendPath}/token`,
    data
  );
};

export const verifyAuthenticationOtp = (data) => {
  return axiosInstance.put(
    `${apiAppendPath.userTypeApiAppendPath}/verify/otp`,
    data
  );
};

export const resendOtpCode = (data) => {
  return axiosInstance.post(
    `${apiAppendPath.commonAppendPath}/resend/otp`,
    data
  );
};

export const logOut = (data) => {
  return axiosInstance.post(
    `${apiAppendPath.userTypeApiAppendPath}/logout`,
    data
  );
};

export const generateToken = (data) => {
  return axiosInstance.post(
    `${apiAppendPath.userTypeApiAppendPath}/generate-token`,
    data
  );
}

import { io } from 'socket.io-client';
import config from "../config";

const { api } = config;

const socket = io(api.websocketBaseUrl, { secure: true, autoConnect: false, transports: ['websocket'] });

export default socket;

export function socket_init() {
    socket.on('connect', () => {
        console.log("==================== connected to socket =================================");
    })

    socket.on("disconnect", () => {
        console.log("=========== disconnected socket ==============");
    });
}

export function socket_close() {
    if (socket && typeof socket.close === "function") {
        socket.close();
        console.log("==================== closed socket =================================");
    }
}

import React, { useState, useRef } from "react";
import {
  Form,
  Spin,
  Input,
  Button,
  message,
  Layout,
  Select,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { generateToken } from "../../api/UserTypeAPI";

import { StyledComponentWrapper, Title } from "./Profile.styles";

const { Content } = Layout;
const { Option } = Select;

const TIME_UNIT = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year'
};

const PORTAL_TYPE = {
  MEMBER_PORTAL: 'MEMBER_PORTAL',
  ADMIN_PORTAL: 'ADMIN_PORTAL',
}

const { DAY, WEEK, MONTH, YEAR } = TIME_UNIT;

const validateLifespan = (value, unit) => {
    const timeUnits = {
      [DAY]: 1,
      [WEEK]: 7,
      [MONTH]: 30,
      [YEAR]: 365
    };

    const totalDays = value * timeUnits[unit];
    if (totalDays > 365) {
      message.error('Token expiration cannot exceed 1 year');
      return false;
    }
    return true;
};

const DeveloperSettings = () => {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [lifespanValue, setLifespanValue] = useState(1);
  const [lifespanUnit, setLifespanUnit] = useState(DAY);
  const [portalType, setPortalType] = useState(PORTAL_TYPE.MEMBER_PORTAL);
  const tokenRef = useRef(null);

  const handleGenerateToken = async () => {
    if (!validateLifespan(lifespanValue, lifespanUnit)) {
      return;
    }

    setLoading(true);
    try {
      const response = await generateToken({ value: lifespanValue, unit: lifespanUnit, portalType });
      const { payload } = response.data;
      setToken(payload);
      message.success("Token generated successfully!");

      if (tokenRef.current) {
        tokenRef.current.scrollIntoView({ behavior: 'smooth' });
      }

    } catch (error) {
      message.error("Failed to generate token.");
    } finally {
      setLoading(false);
    }
  };

  const handleCopyToken = () => {
    navigator.clipboard.writeText(token);
    message.success("Token copied to clipboard!");
  };

  return (
    <Content>
      <StyledComponentWrapper>
        <Title>Developer Settings</Title>
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
          <Form layout="vertical">
            <Form.Item label="Portal Type">
              <Select
                style={{ width: '30%' }}
                value={portalType}
                onChange={setPortalType}
              >
                <Option value={PORTAL_TYPE.MEMBER_PORTAL}>Member Portal</Option>
                <Option value={PORTAL_TYPE.ADMIN_PORTAL}>Admin Portal</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Token Lifespan">
              <Input.Group compact>
                <Input
                  style={{ width: '15%' }}
                  type="number"
                  min={1}
                  max={365}
                  value={lifespanValue}
                  onChange={(e) => setLifespanValue(e.target.value)}
                />
                <Select
                  style={{ width: '15%' }}
                  value={lifespanUnit}
                  onChange={(value) => setLifespanUnit(value)}
                >
                  <Option value={DAY}>Day</Option>
                  <Option value={WEEK}>Week</Option>
                  <Option value={MONTH}>Month</Option>
                  <Option value={YEAR}>Year</Option>
                </Select>
              </Input.Group>
            </Form.Item>
            <Form.Item>
              <Button style={{ width: '15%' }} type="primary" onClick={handleGenerateToken} loading={loading}>
                Generate Token
              </Button>
            </Form.Item>
            <div ref={tokenRef}>
              {token && (
                <>
                  <Form.Item label="Generated Token">
                    <Input.TextArea value={token} disabled={!token} rows={4} />
                  </Form.Item>
                  <Form.Item>
                    <Button style={{ width: '15%' }} onClick={handleCopyToken} disabled={!token}>
                      Copy Token
                    </Button>
                  </Form.Item>
                </>
              )}
            </div>
          </Form>
        </Spin>
      </StyledComponentWrapper>
    </Content>
  );
};

export default DeveloperSettings;

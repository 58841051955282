import axiosInstance from "./AxiosInstance";
import config from "../config";

const { apiAppendPath } = config;

export const getTicket = (filterData) => {
  return axiosInstance.get(
    `${apiAppendPath.caseTicketsAppendPath}/get-ticket`,
    { params: filterData }
  );
};

export const updateTicket = (data) => {
  return axiosInstance.put(
    `${apiAppendPath.caseTicketsAppendPath}/update-ticket`,
    data
  );
};

export const getUnreadTicketsCount = () => {
  return axiosInstance.get(
    `${apiAppendPath.caseTicketsAppendPath}/get-unread-tickets-count`
  );
};

export const getUnreadMessagesCountByTicketIds = (ticketIds) => {
  return axiosInstance.get(
    `${apiAppendPath.caseTicketsAppendPath}/unread-messages-count-by-ticket-ids`,
    { params: { ticketIds } }
  );
}

export const getCaseTicketClearNotification = (ticketId) => {
  return axiosInstance.get(
    `${apiAppendPath.caseTicketsAppendPath}/clear-ticket-notifications`,
    { params: ticketId }
  );
};
export const markTicketAsRead = (ticketId) => {
  return axiosInstance.put(
    `${apiAppendPath.caseTicketsAppendPath}/mark-ticket-as-read`,
    { ticketId }
  );
};

const TicketApi = {
  getTicket,
  updateTicket,
  getUnreadTicketsCount,
  getCaseTicketClearNotification,
  getUnreadMessagesCountByTicketIds,
  markTicketAsRead,
};

export default TicketApi;

/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Layout, message } from "antd";
import { StyledComponentWrapper } from "./Profile.styles";

//import components
import ChangePasswordComponent from "./ChangePasswordComponent";

//import other files and API function
import { updateUserPassword } from "../../api/UserTypeAPI";

const { Content } = Layout;

const ChangePassword = () => {
  let timeoutRef = null;
  let history = useHistory();
  const [loading, setLoading] = useState(false);

  // notification message configuration
  message.config({
    duration: 1.5,
  });

  useEffect(() => {
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, []);

  const onPasswordChange = (passwordDetails) => {
    setLoading(true);
    updateUserPassword(passwordDetails)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          message.success(
            "Successfully Updated Password! Redirecting to login page. Please login again with new password."
          );
          timeoutRef = setTimeout(() => {
            localStorage.clear();
            history.push("/login");
          }, 1500);
        } else {
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to Update password. Please verify the current password and new password!"
            );
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(
          "================== password update error ========================",
          error
        );
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error(
              "Unable to Update password. Please verify the current password and new password!"
            );
          }
        } else {
          message.error(
            "Unable to Update password. Please verify the current password and new password!"
          );
        }
        setLoading(false);
      });
  };

  return (
    <>
      <Content>
        <StyledComponentWrapper>
          <ChangePasswordComponent
            loadingIndicator={loading}
            resetPasswordCallback={onPasswordChange}
          />
        </StyledComponentWrapper>
      </Content>
    </>
  );
};

export default ChangePassword;
